import Vue from 'vue';
import VueI18n from 'vue-i18n';
import es from 'vuetify/lib/locale/es';
import en from 'vuetify/lib/locale/en';
import enMessagesVee from 'vee-validate/dist/locale/en.json';
import esMessagesVee from 'vee-validate/dist/locale/es.json';

Vue.use(VueI18n);

const messages = {
	es: {
		...require('./locals/es_MX.json'),
		validation: esMessagesVee.messages,
		$vuetify: es,
	},
	en: {
		...require('./locals/en_US.json'),
		validation: enMessagesVee.messages,
		$vuetify: en,
	},
};
const i18n = new VueI18n({
	locale: process.env.VUE_APP_LANGUAGE || 'en',
	fallbackLocale: process.env.VUE_APP_FALLBACK_LANGUAGE || 'en',
	messages,
});
export { i18n };
