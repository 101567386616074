require('dotenv').config();
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import * as VueGoogleMaps from 'vue2-google-maps';
import TrendChart from 'vue-trend-chart';
import { i18n } from './lang/index';
import titleMixin from './mixins/titleMixing';
import * as LottiePlayer from '@lottiefiles/lottie-player';
import './lang/vee-validate';

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_API,
		libraries: 'places',
	},
});
Vue.use(LottiePlayer);
Vue.use(TrendChart);
Vue.mixin(titleMixin);

new Vue({
	router,
	vuetify,
	i18n,

	render: (h) => h(App),
}).$mount('#app');
