// translation error in forms
import { regex, is } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import { configure } from 'vee-validate';
import { i18n } from './index';

extend('regex', {
	...regex,
	message: (_, values) => i18n.t('validations.custom.password', values),
});

extend('is', {
	...is,
	message: (_, values) => i18n.t('validations.custom.is', values),
});

extend('decimal', {
	validate: (value, { decimals = '*', separator = '.' } = {}) => {
		if (value === null || value === undefined || value === '') {
			return {
				valid: false,
			};
		}
		if (Number(decimals) === 0) {
			return {
				valid: /^-?\d*$/.test(value),
			};
		}
		const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
		const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

		return {
			valid: regex.test(value),
		};
	},
	message: (_, values) => i18n.t('validations.custom.decimal', values),
});

configure({
	// this will be used to generate messages.
	defaultMessage: (field, values) => {
		//values._field_ = i18n.t(`fields.${field}`);
		return i18n.t(`validation.${values._rule_}`, values);
	},
});
