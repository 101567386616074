import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
	// {
	// 	path: '/',
	// 	name: 'home',
	// 	component: () => import(/* webpackChunkName: "about" */ '../views/app/views/Home.vue'),
	// 	meta: {}
	// },
	{
		path: '/login',
		name: 'login',
		component: () => import(/* webpackChunkName: "about" */ '../views/app/views/Login.vue'),
		meta: {
			requiresAuth: false,
		},
	},

	{
		path: '/admin',
		name: 'admin',
		component: () => import(/* webpackChunkName: "about" */ '../views/app/Frame.vue'),
		meta: {
			requiresAuth: true,
		},
		children: [
			{
				path: 'dashboard',
				name: 'dashboard',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/Dashboard.vue'),
				meta: {
					requiresAuth: true,
					is_business: true,
					is_admin: true,
					is_employee: false,
				},
			},
			{
				path: 'business',
				name: 'business',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/Business.vue'),
				meta: {
					requiresAuth: true,
					is_business: true,
					is_admin: false,
					is_employee: false,
				},
			},
			{
				path: 'businesses',
				name: 'businesses',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/Businesses.vue'),
				meta: {
					requiresAuth: true,
					is_business: false,
					is_admin: true,
					is_employee: false,
				},
			},
			{
				path: 'stadiums',
				name: 'stadiums',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/Stadiums.vue'),
				meta: {
					requiresAuth: true,
					is_business: false,
					is_admin: true,
					is_employee: false,
				},
			},
			{
				path: 'stadium/:id/sections',
				name: 'sections',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/Sections.vue'),
				meta: {
					requiresAuth: true,
					is_business: false,
					is_admin: true,
					is_employee: false,
				},
			},
			{
				path: 'section/:id/rows',
				name: 'rows',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/Rows.vue'),
				meta: {
					requiresAuth: true,
					is_business: false,
					is_admin: true,
					is_employee: false,
				},
			},
			{
				path: 'row/:id/seats',
				name: 'seats',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/Seats.vue'),
				meta: {
					requiresAuth: true,
					is_business: false,
					is_admin: true,
					is_employee: false,
				},
			},
			{
				path: 'products',
				name: 'products',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/Products.vue'),
				meta: {
					requiresAuth: true,
					is_business: true,
					is_admin: true,
					is_employee: true,
				},
			},
			{
				path: 'employees',
				name: 'employees',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/Employees.vue'),
				meta: {
					requiresAuth: true,
					is_business: true,
					is_admin: true,
					is_employee: false,
				},
			},
			{
				path: 'categories',
				name: 'categories',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/Categories.vue'),
				meta: {
					requiresAuth: true,
					is_business: true,
					is_admin: true,
					is_employee: false,
				},
			},
			{
				path: 'orders',
				name: 'orders',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/Orders.vue'),
				meta: {
					requiresAuth: true,
					is_business: true,
					is_admin: true,
					is_employee: true,
				},
			},
			{
				path: 'history',
				name: 'history',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/OrdersHistory.vue'),
				meta: {
					requiresAuth: true,
					is_business: true,
					is_admin: true,
					is_employee: false,
				},
			},

			{
				path: 'reports',
				name: 'reports',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/UserReports.vue'),
				meta: {
					requiresAuth: true,
					is_business: false,
					is_admin: true,
					is_employee: false,
				},
			},

			{
				path: 'users/banned',
				name: 'users/banned',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/BannedUsers.vue'),
				meta: {
					requiresAuth: true,
					is_business: false,
					is_admin: true,
					is_employee: false,
				},
			},

			{
				path: 'settings',
				name: 'settings',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/Settings.vue'),
				meta: {
					requiresAuth: true,
					is_business: true,
					is_admin: true,
					is_employee: true,
				},
			},
			{
				path: 'notifications',
				name: 'notifications',
				component: () => import(/* webpackChunkName: "about" */ '../views/app/views/Notifications.vue'),
				meta: {
					requiresAuth: true,
					is_business: false,
					is_admin: true,
					is_employee: false,
				},
			},
		],
	},
	{
		path: '/*',
		redirect: '/admin/products',
		meta: {
			requiresAuth: false,
		},
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	//console.log('0');
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (localStorage.getItem('weserv.access') == null) {
			return next({
				path: '/login',
				params: { nextUrl: to.fullPath },
			});
		} else {
			let user = JSON.parse(localStorage.getItem('weserv.userData'));
			if (!user) {
				//console.log('no user');
				return next({ name: 'login' });
			}
			//console.log(user.accessLevel);
			//console.log('1');
			if (to.matched.some((record) => record.meta.is_admin)) {
				//console.log('1.1');
				if (user.accessLevel === 'admin') {
					//console.log('1.2');
					//console.log(to);
					return next();
				}
			}
			//console.log('2');
			if (to.matched.some((record) => record.meta.is_business)) {
				//console.log('2.1');

				if (user.accessLevel === 'business') {
					//console.log('2.2');
					return next();
				}
			}
			//console.log('3');
			if (to.matched.some((record) => record.meta.is_employee)) {
				//console.log('3.1');

				if (user.accessLevel === 'employee') {
					//console.log('3.2');
					return next();
				}
			}
		}
	} else {
		//console.log('no auth');
		return next();
	}
});
export default router;
