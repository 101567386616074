import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import { i18n } from '../lang/index';
Vue.use(Vuetify);

export default new Vuetify({
	lang: {
		t: (key, ...params) => i18n.t(key, params),
	},
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#3F6177',
				secondary: '#808080',
				tertiary: '#BDBDBD',
				accent: colors.shades.black,
				table: colors.red.accent3,
				background: '#FFFFFF',
				navbar: '#BAB2B5',
			},
			dark: {
				primary: '#3F6177',
				secondary: colors.grey.darken3,
				tertiary: colors.grey.darken3,
				accent: colors.shades.black,
				table: colors.red.accent3,
				background: '#1A1A1D',
				navbar: '#4E4E50',
			},
		},
	},
});
